.privacy-policy-content-section {
    height: 100%;
    min-width: 0px;
    grid-column-end: span 2;
    grid-row-end: span 1;
}

.privacy-policy-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 20px 80px;
}

.privacy-policy-container:not(:first-child) {
    border-top: none;
}

.privacy-policy-text-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0px;
}

.privacy-policy-heading-text {
    margin: 0px;
    font-size: 38px;
    line-height: 52px;
    font-weight: 700;
    color: var(--main-theme-color);
}

.privacy-policy-heading-text sup {
    font-size: 10px;
    vertical-align: super;
}

.privacy-policy-subheading-text {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
}

.timestamp-label {
    text-align: start;
    margin: 0;
    color: var(--grey-color);
}

.legal-list>li {
    margin-left: 20px;
    text-align: start;

    font-size: var(--legal-font-size);
    line-height: var(--legal-line-height);
    font-weight: var(--legal-font-weight);
    font-family: var(--legal-font-family);
    text-align: justify;

}

.legal-text {
    text-align: start;

    font-size: var(--legal-font-size);
    line-height: var(--legal-line-height);
    font-weight: var(--legal-font-weight);
    font-family: var(--legal-font-family);
    text-align: justify;
}

a {
    color: var(--main-theme-color);
}

.privacy-policy-text-container>hr {
    height: 5px;
    border-width: 0px;
    margin: 32px 24px;
    background-color: transparent;
    color: transparent;
}


@media screen and (max-width: 940px) {
    .privacy-policy-heading-text {
        word-break: break-all;
        overflow: hidden;
    }

    .privacy-policy-heading-text > sup {
        display: none;
    }
}

@media screen and (min-width: 1921px){
    .privacy-policy-text-container {
        max-width: 1400px;
    }
}