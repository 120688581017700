.overview-header>h1 {
    font-family: Gilroy;
    font-weight: 600;
    font-size: 60px;

    color: var(--main-theme-color);

    margin: 1em 0 0 0;
}

.overview-header>h3 {
    font-family: Gilroy;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 18px !important;
    letter-spacing: .15em !important;
    line-height: 1.5em;
    color: #000;

    margin-bottom: 1em;
}

.overview-subcontent div:last-of-type {
    margin-top: 10%;
}

.overview-header img {
    margin-left: 0px;
    width: auto;
    height: 22px;
}

.overview-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    background: transparent;
}

.testimonial-header {
    margin: 0 !important;
    padding-bottom: 0px !important;
}

.overview-header {
    text-align: center;
    margin-bottom: 5em;

    min-width: 100%;
}

.overview-content {
    min-height: 550px;
    margin-top: -2em;
    width: 58%;

    font-family: Gilroy;
}

.overview-content>h2 {
    font-size: 22px;
    font-weight: 500;
}

.overview-content>h3 {
    font-size: var(--subheading-text);
    font-weight: normal;
    letter-spacing: 0.15em;
    line-height: 2em;
    margin: 0;
    text-transform: uppercase;
}

.overview-content>p {
    font-size: var(--main-text);
    line-height: 1.5em !important;
}

.subheading-text {
    font-size: var(--main-text) !important;
    font-weight: normal;
}

.overview-subcontent {
    display: block;
    width: calc(36% - 2em);
    margin-left: 2em;
    margin-top: 0em;
}

.subcontent-header {
    margin-top: 0;
    padding-bottom: 10%;

    position: relative;
    text-transform: uppercase;

    font-family: Gilroy;
    font-weight: normal;
    font-size: var(--main-text);
}

.subcontent-header>span {
    position: absolute;
    left: 0em;
    top: 0.5em;
}

.overview-content ul {
    margin-left: 0;
    padding-left: 0;
}

:root {
    --overview-subcontent-bullet-point-spacing: 10px;
}

.overview-subcontent ul li {
    position: relative;
    list-style: none;
    text-transform: uppercase;
    padding: var(--overview-subcontent-bullet-point-spacing);
    font-size: var(--main-text);
    letter-spacing: .15em !important;
    line-height: 2em;
    font-weight: normal;
    margin-bottom: 10px;
    transition: all 0.1s ease-out;
}

.overview-subcontent ul li:before {
    position: absolute;
    left: -20px;
    top: -1px;
    padding: var(--overview-subcontent-bullet-point-spacing);
    content: '→';
    color: var(--secondary-theme-color);
    display: block;
    opacity: 0;
    transition: all 0s ease-out;
}

.overview-subcontent ul li:hover:before {
    opacity: 1;
}

.overview-subcontent ul li a {
    color: var(--main-theme-color);
    border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.overview-subcontent ul li:hover a {
    color: var(--secondary-theme-color);
    padding-left: 1em !important;
}

/* Mobile Displays */
@media screen and (max-width: 940px) {
    .overview-header {
        min-width: 100%;
    }

    .overview-content {
        min-width: 100%;
    }

    .overview-subcontent {
        min-width: 100%;
        margin-top: 10%;
        margin-left: 0;
    }

    .overview-subcontent ul {
        margin-top: 2em;
    }

    .overview-subcontent ul li {
        padding: 5px;
    }

    .overview-subcontent ul li:before {
        padding: 5px;
    }

    .body-button {
        width: 100%;
    }
}