body {
    width: 100%;
}

h3 {
    font-family: Gilroy;
    font-weight: 400;

}

.main-body {
    display: block;
}

#news-section-container {
    position: relative;
    background: var(--main-theme-color);
}

