/* Fonts */
@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-BoldItalic.woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-Bold.woff");
    font-weight: bold;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-SemiBold.woff");
    font-weight: 600;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-SemiBoldItalic.woff");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-Medium.woff");
    font-weight: 500;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-Light.woff");
    font-weight: 400;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-LightItalic.woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-Regular.woff");
    font-weight: normal;
}

@font-face {
    font-family: Gilroy;
    src: url("./fonts/Gilroy/Gilroy-RegularItalic.woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Concourse T3";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/Concourse/Concourse-T3-Regular.woff") format("woff2"), url("./fonts/Concourse/Concourse-T3-Regular.woff") format("woff"), url("./fonts/Concourse/Concourse-T3-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Concourse T3";
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/Concourse/Concourse-T3-Italic.woff2") format("woff2"), url("./fonts/Concourse/Concourse-T3-Italic.woff") format("woff"), url("./fonts/Concourse/Concourse-T3-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Concourse T3";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/Concourse/Concourse-T3-Bold.woff2") format("woff2"), url("./fonts/Concourse/Concourse-T3-Bold.woff") format("woff"), url("./fonts/Concourse/Concourse-T3-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Concourse T3";
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/Concourse/Concourse-T3-Bold-Italic.woff2") format("woff2"), url("./fonts/Concourse/Concourse-T3-Bold-Italic.woff") format("woff"), url("./fonts/Concourse/Concourse-T3-Bold-Italic.ttf") format("truetype");
}

@font-face {
    font-family: Garamond;
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/Garamond/Adobe-Garamond-Pro-Bold.woff2") format("woff2"), url("./fonts/Garamond/Adobe-Garamond-Pro-Bold.woff") format("woff"), url("./fonts/Garamond/Adobe-Garamond-Pro-Bold.ttf") format("truetype");
}

@font-face {
    font-family: Garamond;
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/Garamond/Adobe-Garamond-Pro-Bold-Italic.woff2") format("woff2"), url("./fonts/Garamond/Adobe-Garamond-Pro-Bold-Italic.woff") format("woff"), url("./fonts/Garamond/Adobe-Garamond-Pro-Bold-Italic.ttf") format("truetype");
}

@font-face {
    font-family: Garamond;
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/Garamond/Adobe-Garamond-Pro-Italic.woff2") format("woff2"), url("./fonts/Garamond/Adobe-Garamond-Pro-Italic.woff") format("woff"), url("./fonts/Garamond/Adobe-Garamond-Pro-Italic.ttf") format("truetype");
}


/* CSS Rules */

html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    background: var(--bg-color);
}

* {
    font-family: Gilroy;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

ul {
    padding-left: 0;
    margin-left: 0;
}

ul li {
    max-width: 35em;
}

a {
    text-decoration: none;
    font-family: inherit;
    padding-bottom: .1em;
    transition: all 0.1s ease-out;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    outline: none;
    background-color: transparent;
}

img {
    border-style: none;
}


/* Body Buttons */
.body-button {
    align-items: center;
    justify-content: center;
    display: inline-flex;
    font-size: 14px !important;
    letter-spacing: .15em !important;
    line-height: 1.5em;

    border: 1px solid #000;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    font-size: .9em;
    letter-spacing: 1.5px;

    margin-top: 1.5em;
    margin-bottom: 1em;
    min-width: 150px;
    padding: 1.1em 4em 1em 4em;

    text-align: center;

    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.body-button>a {
    color: #000;
    text-decoration: none;
    letter-spacing: 1px;


    position: relative;
    transition: all .45s ease-Out;
}

.body-btn-circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    transition: all .5s ease-Out;
    top: 20px;
    left: 70px;
}

.body-button:hover .body-btn-circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
}

.body-button:hover a {
    color: #fff;
}

/* General Styling */
.main-body {

    margin: 0px auto;
    padding: 0px 16px 50px;
}

.main-body-heading {
    margin: 0px;
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    padding: 120px 0px 100px;
}

.index-container {
    width: 100%;
    overflow: hidden;
    padding: 6em 4em;
    box-sizing: border-box;
    padding-bottom: 2em !important;
    color: #212121;
}



@media screen and (min-width: 1921px){
    .main-body {
        max-width: 1800px !important;
    }
}